export default defineNuxtRouteMiddleware((to, from) => {
  if (
    import.meta.client
      && from.query.storeFilter
      && !to.query.storeFilter
      && from.path !== to.path // Skip the redirection on filtering
  ) {
    to.query.storeFilter = from.query.storeFilter
    return navigateTo(to)
  }
})
